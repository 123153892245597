<!--
  ┌───────────────────────┬─────────────────────────────────────────────────────────────────────────────────────────────────┐
  │ * TABLE RENDER 1.3 *  │  Librería de construcción y manejo de tablas AgGrid con acciones y funcionalidades complejas    │
  ├───────────────────────┴─────────────────────────────────────────────────────────────────────────────────────────────────┤
  │                                                                                                                         │
  │    Principales funcionalidades:                                                                                         │
  │                                                                                                                         │
  │     * Estructuras de columnas dinámicas                                                                                 │
  │     * Columna con acciones (botones y switches)                                                                         │
  │     * Redimensionado automático o manual de columnas                                                                    │
  │     * Buscador/filtro en tiempo real                                                                                    │
  │     * Ordenamiento A-Z de texto y fechas (con o sin animación)                                                          │
  │     * Al clickear una fila puede llevar a URL o ejecutar una función JS                                                 │
  │     * Soporte para Firebase (obtención de filas mediante .data())                                                       │
  │     * Checkboxes                                                                                                        │
  │                                                                                                                         │
  │                                                                                                                         │
  ├────────────────────────────────────┬──────────────┬──────────────────────────┐                                          │
  │ Autor original: Sebastian Findling │  www.seb.cl  │  github.com/sebfindling  │                                          │
  └────────────────────────────────────┴──────────────┴──────────────────────────┴──────────────────────────────────────────┘

  Actions (switchKey = cambia de color si la key es true o false)
  <table-render ... actions="actions">
  this.actions = [{action: id => this.METODO(id), icon: 'eye', color: 'green', switchKey: 'propiedad-a-supervisar'}]

  Se puede usar {nombre_de_un_campo} dentro de un campo para mostrar el contenido de otro
  Se puede usar "source" en vez de "key" para que se ejecute una función (la cual recibirá el id de la fila como parámetro)
-->

<template>
  <div class="table-render" :class="{'has-link':link}">
    <div id="table-wrapper">
      <ag-grid-vue
        v-if="data[0]"
        ref="agGridTable"
        class="ag-theme-alpine my-4 ag-grid-table"
        @selection-changed="onSelectionChanged"
        @grid-ready="gridReady"
        :gridOptions="gridOptions"
        :pagination="false"
        :rowData="data"
        headerHeight="35"
        rowHeight="35"
        :columnDefs="visibleColumns"
        :suppressPaginationPanel="true"
        :suppressRowClickSelection="true">
      </ag-grid-vue>
    </div>
  </div>
</template>

<style lang="scss">
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css);

/* alinear texto al medio verticalmente en las rows */
.ag-row .ag-cell {
  display: flex;
  align-items: center;
}
/* arreglar interlineado */
.ag-theme-alpine .ag-cell { line-height: inherit; }
.table-render {
  .ag-row {cursor: text !important;}
  .ag-cell.ag-cell-inline-editing, .ag-theme-alpine .ag-cell-inline-editing {
    height: 43px !important;
    background: green !important;
  }
  .ag-row-focus .ag-cell-focus {
    border: 1px solid transparent !important;
  }
  .ag-cell.ag-cell-inline-editing input[type=text], .ag-theme-alpine .ag-cell-inline-editing input[type=text] {
    padding-left: 16px !important;
  }
  .ag-cell-auto-height {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  } 
  &.has-link {
    .ag-row {cursor: pointer !important;}
  }
}
</style>

<script>
const locale = 'es'; //esto podría venir en otro import por ejemplo

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import './agGridStyleOverride.css';
import './alpine-rq.css';
import {AgGridVue} from 'ag-grid-vue';
import moment from 'moment';
import ('moment/locale/'+locale);

/**
 * Un hermoso renderizador de tablas
 */
export default {
  name: 'TableRender',
  components: {AgGridVue},
  //nota: si usamos firebase procesaremos cada fila con .data()
  props: ['rows', 'schema', 'lists', 'id', 'link', 'functions', 'showCheckboxes', 'firebase', 'replacementsReturnedKey',
   'actions', 'actionsLabel', 'loading', 'selectedRows', 'actionsWidth', 'noAutoResize', 'replacements'],
  data () {
    return {
      data: [],
      visibleColumns: [],
      actionsHandlerId: '',
      gridApi: null,
      columnApi: null,
      gridOptions: {
        domLayout:'autoHeight',
        animateRows: false,
        onRowClicked: this.select,
        rowSelection: 'multiple',
        onGridSizeChanged: this.gridSizeChanged,
        localeText: {
          noRowsToShow: '&nbsp;'
          // noRowsToShow: 'No hay datos que mostrar'
        }
      },
      defaultColumnOptions: {
        suppressMovable: true,
        suppressMenu: true
      }
    }
  },
  mounted () {
    // this.boot()
    this.$nextTick(() => { if (this.rows[0]) this.boot() })
  },
  watch: {
    rows (/*current, previous*/) {
      // actualizar tamaños columnas con datos nuevos
      this.$nextTick(() => {
        if (this.rows[0]) {
          this.boot()
          // if (!this.noAutoResize) this.autoSizeAll(false)
        } else {
          this.data = []
        }
      })
    },
    loading () {
      if (this.loading) {
        this.gridOptions.api.showLoadingOverlay()
      } else {
        this.gridOptions.api.hideOverlay()
      }
    }
  },
  methods: {
    autoSizeAll (skipHeader) {
      const allColumnIds = []
      this.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId)
      })
      this.columnApi.autoSizeColumns(allColumnIds, skipHeader)
    },
    gridReady (params) {
      this.columnApi = params.columnApi
      this.api = params.api
    },
    gridSizeChanged (/*params*/) { //https://www.ag-grid.com/javascript-grid-width-and-height/
      if (this.noAutoResize) return
      const gridWidth = document.getElementById('table-wrapper').offsetWidth
      const columnsToShow = []
      const columnsToHide = []
      let totalColsWidth = 0
      const allColumns = this.columnApi.getAllColumns()
      for (let i = 0; i < allColumns.length; i++) {
        const column = allColumns[i]
        totalColsWidth += column.getMinWidth()
        if (totalColsWidth > gridWidth) {
          columnsToHide.push(column.colId)
        } else {
          columnsToShow.push(column.colId)
        }
      }
      //configuar AG-Grid para que ajuste los tamaños de las filas y columnas automaticamente
      this.columnApi.setColumnsVisible(columnsToShow, true)
      this.columnApi.setColumnsVisible(columnsToHide, false)
      this.api.sizeColumnsToFit()
      this.api.resetRowHeights()
    },
    boot () {
      //añadir acciones a una nueva columna, si hay
      this.actionsHandlerId = new Date().getTime() + Math.floor(10000 * Math.random()) //hacer esto siempre para refrescar ids existentes
      if (this.actions && !this.schema.find(col => col.key === 'actions')) {
        this.schema.push({label: this.actionsLabel || '', visible:true, key:'actions', width:+this.actionsWidth})
      }
      this.gridApi = this.api
      this.columnApi = this.gridOptions.columnApi
      const finalColumns = []
      //escuchar búsquedas
      this.$root.$on('tableSearch', (data) => {
        if (data.id === this.id) this.gridApi.setQuickFilter(data.query)
      })
      //determinar columnas
      for (const i in this.schema) {
        const field = this.schema[i]
        if (field.visible) {
          //configuracion de checkbox usada  si se activa el parámetro showCheckboxes
          const checkboxOptions = {
            checkboxSelection: !!this.showCheckboxes,
            headerCheckboxSelectionFilteredOnly: true,
            headerCheckboxSelection: !!this.showCheckboxes
          }
          //si es fecha formateada por parametro momentjs (que debe ser un string asi: "LL:es:TextoIrrelevante"
          //LL = format('LL'), es = locale('es'), TextoIrrelevante = texto que, si viene en una fila, hará que quede al final de la tabla
          let filterParams = {comparator: ()=>{}}
          if (field.momentjs) {
            moment.locale('es');
            filterParams = {
              comparator: (anterior, cellValue, x, y, ordenInverso) => {
                //se puede pasar un parametro de texto para dejar las filas que lo contengan siempre al final
                if (field.momentjs.split(':')[2] && anterior === field.momentjs.split(';')[2] && !ordenInverso) {
                  cellValue = '01/01/2099 00:00:00'; anterior = '01/01/1970 00:00:00'
                }
                if (field.momentjs.split(':')[2] && cellValue === field.momentjs.split(';')[2] && ordenInverso) {
                  cellValue = '01/01/2099 00:00:00'; anterior = '01/01/1970 00:00:00';
                }
                console.log('te parseo',field.momentjs.split(':'));
                const unixAnterior = moment(anterior, field.momentjs.split(':')[0].replace(/;/g,':')).unix()
                const unix = moment(cellValue, field.momentjs.split(':')[0].replace(/;/g,':')).unix()
                console.log('comparo',anterior, '|', cellValue, unixAnterior, unix);
                return unixAnterior < unix ? '1' : '-1'
              }
            }
          }
          //añadir opciones default y las especificadas mediante props
          const self = this;
          const theColumn = {
            //construir columna y si se activan los checkbox y es la primera columna, añadir uno al lado
            ...{...this.showCheckboxes && +i === 0 ? checkboxOptions : {}, ...this.z, ...field.options},
            headerName: field.label,
            field: field.key,
            autoHeight: true,
            width: field.width,
            cellStyle: {'white-space': 'normal'},
            ...filterParams,
            cellRenderer (cell) {
              if (typeof cell.value === 'number') return cell.value
              if (!cell.value) return
              //parsear HTML (usar {nombre_del_campo} para reemplazarlo dinamicamente)
              if (cell.value.indexOf && !!~cell.value.indexOf('{') && !!~cell.value.indexOf('}')) {
                const vars = [...cell.value.matchAll(/{([\w]+)}/g)]
                for (const v of vars) {
                  cell.value = cell.value.replace(new RegExp(v[0], 'g'), cell.data[v[1]])
                }
                return cell.value
              }
              //ejecuto función solicitada enviándole el parámetro que ésta desea recibir y muestro su resultado
              if (self.replacements && self.replacements[cell.column.colId]) {
                return self.replacements[cell.column.colId](cell.data[cell.column.colId]);
                // return self.replacements[cell.column.colId](cell.data[self.replacementsReturnedKey]); //TODO: que pueda recibir el contenido de otra key si quiere
              }
              else return cell.value
            },
            sortable: true,
            resizable: true,
            filter: true
          }
          finalColumns.push(theColumn)
        }
      }
      this.visibleColumns = finalColumns

      //procesar filas para ejecutar funciones solicitadas, si las hay
      this.data = []
      for (const y in this.rows) {
        const rowObject = !this.firebase ? this.rows[y] : this.rows[y].data()
        for (const [paramId, paramValue] of Object.entries(rowObject)) {
          if (this.functions && this.functions[paramId]) {
            rowObject[paramId] = paramValue
          }
        }
        //implementar columna con acciones si corresponde
        if (this.actions) {
          const id = this.actionsHandlerId
          rowObject.actions = ''
          window.actionHandlers = []

          this.actions.map((action, i) => {
            //no mostrar si cumple con el criterio del parámetro 'hide'
            //el cual es una función que recibe el id de la fila y devuelve true o false
            if (typeof action.hide === 'function' && action.hide(this.rows[y]._id)) return;
            //poner la funcion en window para acceder desde el boton, porque estará en JS puro
            //esta técnica también permite que se ejecuten funciones de Vue en el callback
            window.actionHandlers[`${id}_${i}`] = action.action
            //los botones pueden ser switch que cambian de clase segun un valor de la fila
            //uso: parámetro switchKey="estaActivo" hará que el botón cambie de clase "is-switch" a "is-switch-true"
            //     según si el parámetro "estaActivo" es "true" o "false"
            let isTrue = ''
            if (action.switchKey && rowObject[action.switchKey]===true) isTrue='-true';
            //crear botón que irá en la columna de acciones
            rowObject.actions += `
                <button class="btn-action vs-button vs-button-filled pl-2 pr-2 ${action.switchKey?`is-switch${isTrue}`:''}
                switch-ownid-${rowObject.id}_${action.switchKey}" style="background-color:${action.color}"
                onclick="window.actionHandlers['${id}_${i}']('{id}'); event.stopPropagation()">
                <i class="fas fa-${action.icon}"></i> ${action.text || ''}</button>`
          })
        }
        this.data.push(rowObject)
      }
    },
    select (row) {
      if (!this.link) return //no hay link asi que adios
      if (typeof this.link === 'string') {
        this.$router.push(this.link.replace(':id', row.data.id))
      } else {
        this.link(row.data)
      }
    },
    onSelectionChanged () {
      this.$emit('update:selectedRows', this.gridApi.getSelectedRows())
    }
  }
}
</script>

<style lang="scss">
.table-render {
  .btn-action { min-width: 18px; font-size: 13px; padding: 7px 7px; margin:0; }
  .ag-theme-alpine .ag-cell {
    line-height: 20px;
    text-align: initial;
  }
  .is-switch {
    background-color: gray !important;
    color:white;
  }
  .is-switch-true {
    background-color: #11c611 !important;
  }
}
</style>
